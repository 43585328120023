@font-face{
    font-family: "Gilroy Regular";
    src: url("/fonts/5538977/b261487c-2966-4089-8551-6555085406ad.woff2") format("woff2"),
    url("/fonts/5538977/bf190033-35ff-42cf-b90c-f5321a94a3df.woff") format("woff");
    font-weight: 300;
}

@font-face{
    font-family: "Gilroy ExtraBold";
    src: url("/fonts/5538999/9aa3a833-f885-4df4-975c-c504bb6a7b67.woff2") format("woff2"),
    url("/fonts/5538999/824bd825-cb90-45d8-ac5e-3997fd8632ac.woff") format("woff");
    font-weight: 800;
}