.datenschutz-impressum {

    &__text {
        font-family: 'Gilroy Regular', sans-serif;
        font-weight: 400;

        strong {
            font-family: 'Gilroy ExtraBold', sans-serif;
            font-weight: 800;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .datenschutz-impressum {
        @include abstand($layout, margin, bottom, 172);
        display: flex;
        justify-content: center;

        .body--impressum & {
            @include abstand($layout, margin, bottom, 0)
        }

        &__text {
            @include schriftart($layout, 24, 28);


            p {
                @include abstand($layout, margin, alle, 24, 0, 24, 0);

                &:first-child {
                    @include abstand($layout, margin, alle, 0);
                }

                &:last-child {
                    @include abstand($layout, margin, alle, 0);
                }
            }
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .datenschutz-impressum {
        display: flex;
        justify-content: center;
        @include abstand($layout, margin, bottom, 20);
        
        .body--datenschutz & {
            @include abstand($layout, margin, bottom, 294);
        }

        &__text {
            @include schriftart($layout, 30, 34);
            width: spalten($layout, 6, 10, 0, 0);

            p {
                @include abstand($layout, margin, alle, 38, 0, 38, 0);

                &:first-child {
                    @include abstand($layout, margin, alle, 0);
                }

                &:last-child {
                    @include abstand($layout, margin, alle, 0);
                }
            }
        }
    }
}