.main {
    position: relative;
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .main {
        @include abstand($layout, padding, alle, 0, 24, 0, 24);
    }
}

@include mediaquery(desktop) {
    
    $layout: desktop;

    .main {
        @include abstand($layout, padding, alle, 0, 20, 0, 20);

        body[data-menue-aktiv="true"] & {
            pointer-events: none;
        }
    }
}