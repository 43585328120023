.startseite {

    &__projekt-titel {
        color: map-get($farben, hellblau-text);
        font-family: 'mrs-eaves-xl-serif', serif;
        font-weight: 900;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__video, &__bild {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &__iframe {
        left: 50%;
        min-height: 100vh;
        opacity: 1;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
    }

    &__blurbild {
        display: block;
        filter: blur(80px);
        height: 100%;
        position: absolute;
        transform: scale(1.25);
        width: 100%;
        z-index: -1;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .startseite {
        // 140 == header 
        height: calc((var(--vh, 1vh) * 100) - groesse($layout, 140));

        &__projekt-titel {
            @include schriftart($layout, 20, 25);
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: groesse($layout, 20);
            margin-left: spalten($layout, 0, 4, 0, 2);
            width: spalten($layout, 3, 4, 0, 0);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .startseite {
        align-items: flex-end;
        display: flex;
        height: calc((100vh - groesse($layout, 245)));

        &::after {
            position: absolute;
            content: ' ';
            background-image: linear-gradient(rgb(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.4) 100%);
            height: groesse($layout, 310);
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: -1;
        }

        &__projekt-titel {
            @include schriftart($layout, 32, 36);
            align-items: flex-end;
            display: flex;
            margin-bottom: groesse($layout, 40);
            margin-left: spalten($layout, 0, 14, 0, 2);
        }
    }
}