.person {

    &__introtext,
    &__inhalt {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }

    &__inhalt-bild {
        overflow: hidden;
    }

    &__bild-video {
        position: relative;
    }

    &__inhalt-bild iframe {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &__blurbild {
        display: block;
        filter: blur(80px);
        height: 100%;
        position: absolute;
        transform: scale(1.25);
        width: 100%;
        z-index: -1;
    }

    &__inhalt--1 & {

        &__inhalt {

            &-text {
                font-family: 'Gilroy Regular', sans-serif;
                font-weight: 400;
            }
        }
    }

    &__inhalt--2 & {

        &__inhalt-titel {
            font-family: "mrs-eaves-xl-serif", serif;
            font-weight: 900;
        }

    }

    &__inhalt {

        & a {
            color: map-get($farben, hellblau-text);
            text-decoration: none;
        }
    }

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .person {
        
        &__introtext {
            @include abstand($layout, margin, bottom, 40);
            @include schriftart($layout, 24, 28);
        }
        
        &__spaltenbreite {
            width: 100%;
        }
        
        &__spaltenabstand {
            width: 0;
        }

        &__inhalt {
            width: 100%;

            &--1 {
                @include abstand($layout, padding, bottom, 40);
            }

            &:nth-last-child(1) {
                @include abstand($layout, padding, bottom, 105);
            }
        }

        &__inhalt--1 & {

            &__inhalt-titel {
                @include schriftart($layout, 20, 24);
            }
            
            &__inhalt-text {
                @include schriftart($layout, 20, 24);
            }
        }
        
        &__inhalt--2 & {
            
            &__inhalt-titel {
                @include schriftart($layout, 30, 30);
                margin-bottom: groesse($layout, 18)
            }
            
            &__inhalt-text {
                @include schriftart($layout, 20, 25);
            }
        }

        &__inhalt-bild {
            @include abstand($layout, margin, alle, 40, -14, 20, -14);

            &--1 {
                @include abstand($layout, margin, alle, 0, -14, 40, -14);
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .person {
        @include abstand($layout, margin, bottom, 392);
        margin-left: spalten($layout, 2, 10, 0, 1);
        margin-right: spalten($layout, 2, 10, 0, 1);

        &__introtext {
            @include abstand($layout, margin, bottom, 98);
            @include schriftart($layout, 40, 48);
        }

        &__inhalte {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__spaltenbreite {
            width: spalten($layout, 11, 24, 0, 0);
        }

        &__spaltenabstand {
            width: spalten($layout, 2, 24, 0, 2);
        }

        &__inhalt {
            @include abstand($layout, margin, bottom, 98);
            @include schriftart($layout, 20, 24);
            display: block;
            width: spalten($layout, 11, 24, 0, 0);
        }

        &__inhalt--2 & {

            &__inhalt-titel {
                @include schriftart($layout, 30, 30);
                margin-bottom: groesse($layout, 15);
            }
        }

        &__inhalt-bild {
            @include abstand($layout, margin, bottom, 30);
        }
    }

}