.ufe {

    &__text {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .ufe {
        @include abstand($layout, margin, bottom, 30 + 54);

        &--desktop {
            display: none;
        }

        &--mobile {
            @include abstand($layout, padding, alle, 20, 20, 20, 20);

            &:hover {
                .ufe__pfeil {
                    transform: translateY(groesse($layout, -8));
                }
            }
        }

        &__container {
            display: flex;
            justify-content: center;
        }

        &__pfeil {
            height: groesse($layout, 30 + 3);
        }

        &__text {
            @include schriftart($layout, 20, 20);
            padding-top: groesse($layout, 10);
        }
    }

}

@include mediaquery(desktop) {

    $layout: desktop;

    .ufe {
        z-index: 1;
        position: absolute;
        bottom: 0;
        right: 0;

        &:hover {
            .ufe__pfeil {
                transform: translateY(groesse($layout, -8));
            }
        }

        &--desktop {
            @include abstand($layout, padding, alle, 10, 10, 10, 10);
            @include abstand($layout, margin, alle, 0, 50, 35, 0);
        }

        &--mobile {
            display: none;
        }

        &__pfeil {
            height: groesse($layout, 33 + 2);
            width: groesse($layout, 24 + 2);
        }

        &__text {
            @include schriftart($layout, 20, 20);
            padding-top: groesse($layout, 10);
        }

    }
}