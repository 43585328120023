// Raster
$farbe--dev: lightgreen;
$farbe--raster: rgba(0, 0, 255, 0.5);
$farbe--raster-mitte: rgba(0, 0, 0, 0.2);


@mixin raster($layout, $spalten, $spaltenabstand, $seitenrand-links, $seitenrand-rechts) {

    $spaltenabstand: groesse($layout, $spaltenabstand);
    $seitenrand-links: groesse($layout, $seitenrand-links);
    $seitenrand-rechts: groesse($layout, $seitenrand-rechts);

    body[data-dev='true'] {
        .dev::before {
            background: linear-gradient(90deg, $farbe--raster-mitte 0.5px, transparent 0.5px) 0 0,
                        linear-gradient(90deg, $farbe--raster 0.5px, transparent 0.5px) calc($spaltenabstand / 2) 0,
                        linear-gradient(90deg, $farbe--raster 0.5px, transparent 0.5px) calc($spaltenabstand / 2 * -1) 0;
            background-size: calc((100% - ($spalten * $spaltenabstand)) * calc(1 / $spalten) + $spaltenabstand) 1px;
            margin: 0 ($seitenrand-rechts - calc($spaltenabstand / 2)) 0 ($seitenrand-links - calc($spaltenabstand / 2));
            width: calc(100% - $seitenrand-rechts + calc($spaltenabstand / 2) - $seitenrand-links + calc($spaltenabstand / 2));
        }
    }
}

.dev {
    background-color: $farbe--dev;
    display: none;
    font-size: 12px;
    height: 20px;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 9999999;
    
    body[data-dev='true'] & {
        display: flex;

        &::before {
            content: "";
            height: 100%;
            left: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            width: 100%;
        }
    }
}




@include mediaquery(smartphone) {
    $layout: smartphone;

    @include raster($layout, map-get($spalten, $layout), map-get($spaltenabstaende, $layout), map-deep-get($seitenraender, $layout, 'left'), map-deep-get($seitenraender, $layout, 'right'));
    
}

@include mediaquery(desktop) {
    $layout: desktop;

    @include raster($layout, map-get($spalten, $layout), map-get($spaltenabstaende, $layout), map-deep-get($seitenraender, $layout, 'left'), map-deep-get($seitenraender, $layout, 'right'));

}