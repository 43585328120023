.atelier {

    &__introtext {
        font-family: 'mrs-eaves-xl-serif', serif;
        font-weight: 900;
    }

    &__atelierbild {
        overflow: hidden;
        position: relative;

        iframe {
            opacity: 1;
            position: absolute;
        }
    }

    &__blurbild {
        display: block;
        filter: blur(80px);
        height: 100%;
        position: absolute;
        transform: scale(1.25);
        width: 100%;
        z-index: -1;
    }

    &__instagram {
        color: black;
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
        text-decoration: none;

        &:hover {
            color: map-get($farben, hellblau-text);
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .atelier {
        @include abstand($layout, margin, bottom, 172);
        
        &__bildcontainer {
            margin-left: groesse($layout, -14);
            margin-right: groesse($layout, -14);
        }
        
        &__atelierbild {
            height: groesse($layout, 302);
            width: 100%;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: block
            }
        }
        
        &__container {
            @include abstand($layout, margin, top, 40);
        }

        &__introtext {
            @include abstand($layout, margin, bottom, 40);
            @include schriftart($layout, 30, 32);
        }
         
        &__instagram {
            @include abstand($layout, margin, bottom, 15);
            @include schriftart($layout, 20, 24);
        }
        
        &__instagram-logo {
            width: groesse($layout, 40);
            height: groesse($layout, 40);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .atelier {

        &__atelierbild {
            // 100vh - header(245) - abstand(20);
            height: calc(100vh - groesse($layout, 245 + 20));
        }

        &__atelierbild {
            iframe {
                left: 50%;
                min-height: 80vh;
                bottom: 0;
                transform: translateX(-50%);
                width: 100vw;
            }

            &--mobile {
                display: none;
            }

            &--desktop {
                display: block
            }
        }

        &__container {
            @include abstand($layout, margin, top, 98);
            // 98 abstand top, 20 abstand atelierbild, 70 schriftart, 3 Zeilen, 16 gleicht aus
            transform: translateY(groesse($layout, -98 + 20 - 70 * 3 + 16));
        }

        &__introtext {
            @include abstand($layout, margin, bottom, 98);
            @include schriftart($layout, 70, 70);
            margin-left: spalten($layout, 2, 10, 0, 1);
            margin-right: spalten($layout, 2, 10, 0, 1);
        }

        &__instagram {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 30, 34);
            margin-left: spalten($layout, 2, 10, 0, 1);
        }

        &__instagram-logo {
            cursor: pointer;
            height: groesse($layout, 45);
            width: groesse($layout, 45);
            z-index: 1;
        }

        &__instagram-text {
            margin-left: spalten($layout, 2, 10, 0, 1);
        }
    }
}