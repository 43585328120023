@mixin hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv) {

    // Settings
    // ==================================================
    $hamburger-padding-x: 15px !default;
    $hamburger-padding-y: 15px !default;
    $hamburger-linienabstand: calc(($hamburger-hoehe - 3 * $hamburger-strichstaerke) / 2) !default;
    $hamburger-farbe: #000 !default;
    $hamburger-layer-border-radius: 0 !default;
    $hamburger-hover-opacity: 1 !default;
    $hamburger-active-layer-color: $hamburger-farbe-aktiv !default;
    $hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
    $hamburger-animationsdauer: 0.195s;
    $hamburger-kompressionsgeschwindigkeit: 0.12s;
    $hamburger-drehgeschwindigkeit: $hamburger-animationsdauer - $hamburger-kompressionsgeschwindigkeit;

    // To use CSS filters as the hover effect instead of opacity,
    // set $hamburger-hover-use-filter as true and
    // change the value of $hamburger-hover-filter accordingly.
    $hamburger-hover-use-filter: false !default;
    $hamburger-hover-filter: opacity(50%) !default;
    $hamburger-active-hover-filter: $hamburger-hover-filter !default;


    // padding: $hamburger-padding-y $hamburger-padding-x;


    &:hover {
        @if $hamburger-hover-use-filter==true {
            filter: $hamburger-hover-filter;
        }

        @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    body[data-menue-aktiv='true'] & {
        &:hover {
            @if $hamburger-hover-use-filter==true {
                filter: $hamburger-active-hover-filter;
            }

            @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $hamburger-active-layer-color;
            transition: 0s $hamburger-kompressionsgeschwindigkeit linear;
        }
    }

    .hamburger-box {
        width: $hamburger-breite;
        height: calc($hamburger-strichstaerke * 3 + $hamburger-linienabstand * 2);
        display: inline-block;
        position: relative;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: calc($hamburger-strichstaerke / -2);

        &,
        &::before,
        &::after {
            width: $hamburger-breite;
            height: $hamburger-strichstaerke;
            background-color: $hamburger-farbe;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: calc(($hamburger-linienabstand + $hamburger-strichstaerke) * -1);
        }

        &::after {
            bottom: calc(($hamburger-linienabstand + $hamburger-strichstaerke) * -1);
        }
    }



    // Aus Basis
    display: block;
    cursor: pointer;

    transition-property: opacity,
    filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;

    .hamburger-inner {
        transition-duration: $hamburger-drehgeschwindigkeit;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top $hamburger-drehgeschwindigkeit $hamburger-kompressionsgeschwindigkeit ease,
                opacity $hamburger-drehgeschwindigkeit ease;
        }

        &::after {
            transition: bottom $hamburger-drehgeschwindigkeit $hamburger-kompressionsgeschwindigkeit ease,
                transform $hamburger-drehgeschwindigkeit cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }


    @include mediaquery(smartphone) {

        $layout: smartphone;

        body[data-menue-aktiv='true'] & {
            .hamburger-inner {
                transform: rotate(45deg);
                transition-delay: $hamburger-kompressionsgeschwindigkeit;
                transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                &::before {
                    top: 0;
                    opacity: 0;
                    transition: top $hamburger-drehgeschwindigkeit ease,
                        opacity $hamburger-drehgeschwindigkeit $hamburger-kompressionsgeschwindigkeit ease;
                }

                &::after {
                    bottom: 0;
                    transform: rotate(-90deg);
                    transition: bottom $hamburger-drehgeschwindigkeit ease,
                        transform $hamburger-drehgeschwindigkeit $hamburger-kompressionsgeschwindigkeit cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }
}