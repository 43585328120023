@charset "UTF-8";
.bild {
  width: 100%;
}

.dev {
  background-color: lightgreen;
  display: none;
  font-size: 12px;
  height: 20px;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 9999999;
}
body[data-dev=true] .dev {
  display: flex;
}
body[data-dev=true] .dev::before {
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}

@media (max-width: 799px) {
  body[data-dev=true] .dev::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0.5px, transparent 0.5px) 0 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) 1.19vw 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) -1.19vw 0;
    background-size: calc((100% - 9.52vw) * 0.25 + 2.38vw) 1px;
    margin: 0 1.19vw 0 1.19vw;
    width: calc(100% - 2.38vw + 1.19vw - 2.38vw + 1.19vw);
  }
}
@media (min-width: 800px) {
  body[data-dev=true] .dev::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0.5px, transparent 0.5px) 0 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) 0.455vw 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) -0.455vw 0;
    background-size: calc((100% - 9.1vw) * 0.1 + 0.91vw) 1px;
    margin: 0 0.455vw 0 0.455vw;
    width: calc(100% - 0.91vw + 0.455vw - 0.91vw + 0.455vw);
  }
}
html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
body[data-menue-aktiv=true] {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

#seite:after {
  background-color: white;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  color: black;
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  position: relative;
}
li::before {
  content: "–";
  left: 0;
  position: absolute;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 2px solid black;
}

.bild-verhaeltnis {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.bild-verhaeltnis.lazyloaded {
  background-color: white;
  transition: background-color 500ms ease-in;
}

.bild-verhaeltnis img,
.bild-verhaeltnis picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  /*  transition is applied to lazyloaded, not to lazyload */
  transition: opacity 500ms ease-in;
}

@font-face {
  font-family: "Gilroy Regular";
  src: url("/fonts/5538977/b261487c-2966-4089-8551-6555085406ad.woff2") format("woff2"), url("/fonts/5538977/bf190033-35ff-42cf-b90c-f5321a94a3df.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy ExtraBold";
  src: url("/fonts/5538999/9aa3a833-f885-4df4-975c-c504bb6a7b67.woff2") format("woff2"), url("/fonts/5538999/824bd825-cb90-45d8-ac5e-3997fd8632ac.woff") format("woff");
  font-weight: 800;
}
@media (max-width: 799px) {
  .alli {
    display: none;
  }
}
@media (min-width: 800px) {
  .alli {
    margin-top: -0.91vw;
    height: 100%;
    position: absolute;
    left: 0;
    margin-left: calc(((100% - 35.49vw) / 40 * 1 + 0.91vw) * 1 * 1 - 0.91vw);
    top: 0;
    z-index: 1;
  }
  .alli__link {
    padding: 0.91vw 0.91vw 0.91vw 0.91vw;
    align-items: center;
    cursor: pointer;
    display: flex;
    position: sticky;
    text-decoration: none;
  }
  .alli__link:hover .alli__pfeil {
    transform: translateX(-0.73vw);
  }
  .alli__pfeil {
    width: 1.59vw;
    height: 1.64vw;
  }
  .alli__text {
    font-size: 0.91vw;
    line-height: 0.91vw;
    color: black;
    font-family: "Gilroy ExtraBold", sans-serif;
    font-weight: 800;
    padding-left: 0.46vw;
  }
}
.angebot__woerter, .angebot__titel {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.angebot__introtext, .angebot__beschreibung {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
}
.angebot__intro {
  display: flex;
  flex-direction: column;
}
.angebot__introtext {
  flex: 1;
}
.angebot__wort {
  margin: 0;
  transform: translateY(70vw);
  transition: transform 0.5s;
}
.angebot__angebote {
  overflow: hidden;
}
.angebot__angebot {
  background-color: #57E5FF;
  display: flex;
}
.angebot__angebot.aktiv .angebot__wort {
  transform: translateY(0);
}
.angebot__angebot.aktiv .angebot__woerter .angebot__wort:nth-child(2) {
  transition-delay: 0.2s;
}
.angebot__angebot.aktiv .angebot__woerter .angebot__wort:nth-child(3) {
  transition-delay: 0.4s;
}
.angebot__so-text {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.angebot__abschlusstext {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.angebot__abschlusstext strong {
  font-family: "Gilroy ExtraBold", sans-serif;
}
.angebot__abschlusstext a {
  text-decoration: none;
  color: #36deff;
}
.angebot__abschlusstext a:hover {
  text-decoration: underline;
}

@media (max-width: 799px) {
  .angebot__intro {
    min-height: calc(var(--vh, 1vh) * 100 - 33.25vw);
  }
  .angebot__introtext {
    font-size: 7.13vw;
    line-height: 7.6vw;
  }
  .angebot__angebote {
    margin: 0vw -3.33vw 0vw -3.33vw;
  }
  .angebot__angebot {
    padding: 15.44vw 3.33vw 26.13vw 3.33vw;
    flex-direction: column;
    margin-bottom: 3.09vw;
  }
  .angebot__woerter {
    font-size: 11.88vw;
    line-height: 11.88vw;
    margin-top: 13.06vw;
    order: 2;
  }
  .angebot__text {
    order: 1;
  }
  .angebot__titel {
    font-size: 8.08vw;
    line-height: 8.08vw;
    margin-bottom: 4.75vw;
  }
  .angebot__beschreibung {
    font-size: 5.7vw;
    line-height: 6.18vw;
  }
  .angebot__so {
    margin-bottom: 9.5vw;
  }
  .angebot__so-pfeil-svg {
    height: 7.84vw;
    width: 9.5vw;
  }
  .angebot__so-text {
    font-size: 4.75vw;
    line-height: 4.75vw;
    padding-bottom: 2.38vw;
  }
  .angebot__abschlusstext {
    padding: 12.83vw 0vw 12.83vw 0vw;
    font-size: 4.75vw;
    line-height: 5.7vw;
  }
}
@media (min-width: 800px) {
  .angebot__intro {
    min-height: calc(100vh - 11.16vw);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .angebot__introtext {
    font-size: 3.19vw;
    line-height: 3.19vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
    margin-right: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
  .angebot__angebot {
    margin-bottom: 0.91vw;
    padding: 5.92vw calc(((100% - 8.19vw) / 10 * 1 + 0.91vw) * 1 * 1);
  }
  .angebot .angebot__text {
    margin-left: calc(((100% - 6.37vw) / 8 * 0 + 0.91vw) * 1 * 1);
    width: calc(((100% - 6.37vw) / 8 * 3 + 1.82vw) * 1 * 1);
  }
  .angebot__woerter {
    font-size: 6.38vw;
    line-height: 5.92vw;
    width: calc(((100% - 6.37vw) / 8 * 5 + 3.64vw) * 1 * 1);
  }
  .angebot__titel {
    font-size: 1.82vw;
    line-height: 1.82vw;
    margin-bottom: 0.91vw;
  }
  .angebot__beschreibung {
    font-size: 1.37vw;
    line-height: 1.55vw;
  }
  .angebot__so {
    margin-bottom: 4.46vw;
  }
  .angebot__so:hover .angebot__so-pfeil {
    transform: translateY(0.73vw);
  }
  .angebot__so-pfeil-svg {
    height: 1.59vw;
    width: 1.18vw;
  }
  .angebot__so-text {
    font-size: 0.91vw;
    line-height: 0.91vw;
    padding-bottom: 0.46vw;
  }
  .angebot__abschlusstext {
    padding: 8.2vw 0vw 8.2vw 0vw;
    font-size: 1.37vw;
    line-height: 1.55vw;
    width: calc(((100% - 8.19vw) / 10 * 6 + 4.55vw) * 1 * 1);
    margin: 0 auto 0 auto;
    min-height: 33vh;
  }
}
.atelier__introtext {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
}
.atelier__atelierbild {
  overflow: hidden;
  position: relative;
}
.atelier__atelierbild iframe {
  opacity: 1;
  position: absolute;
}
.atelier__blurbild {
  display: block;
  filter: blur(80px);
  height: 100%;
  position: absolute;
  transform: scale(1.25);
  width: 100%;
  z-index: -1;
}
.atelier__instagram {
  color: black;
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
  text-decoration: none;
}
.atelier__instagram:hover {
  color: #36deff;
}

@media (max-width: 799px) {
  .atelier {
    margin-bottom: 40.86vw;
  }
  .atelier__bildcontainer {
    margin-left: -3.33vw;
    margin-right: -3.33vw;
  }
  .atelier__atelierbild {
    height: 71.73vw;
    width: 100%;
  }
  .atelier__atelierbild--desktop {
    display: none;
  }
  .atelier__atelierbild--mobile {
    display: block;
  }
  .atelier__container {
    margin-top: 9.5vw;
  }
  .atelier__introtext {
    margin-bottom: 9.5vw;
    font-size: 7.13vw;
    line-height: 7.6vw;
  }
  .atelier__instagram {
    margin-bottom: 3.56vw;
    font-size: 4.75vw;
    line-height: 5.7vw;
  }
  .atelier__instagram-logo {
    width: 9.5vw;
    height: 9.5vw;
  }
}
@media (min-width: 800px) {
  .atelier__atelierbild {
    height: calc(100vh - 12.07vw);
  }
  .atelier__atelierbild iframe {
    left: 50%;
    min-height: 80vh;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
  }
  .atelier__atelierbild--mobile {
    display: none;
  }
  .atelier__atelierbild--desktop {
    display: block;
  }
  .atelier__container {
    margin-top: 4.46vw;
    transform: translateY(-12.39vw);
  }
  .atelier__introtext {
    margin-bottom: 4.46vw;
    font-size: 3.19vw;
    line-height: 3.19vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
    margin-right: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
  .atelier__instagram {
    margin-bottom: 1.14vw;
    font-size: 1.37vw;
    line-height: 1.55vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
  .atelier__instagram-logo {
    cursor: pointer;
    height: 2.05vw;
    width: 2.05vw;
    z-index: 1;
  }
  .atelier__instagram-text {
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
}
.datenschutz-impressum__text {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.datenschutz-impressum__text strong {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}

@media (max-width: 799px) {
  .datenschutz-impressum {
    margin-bottom: 40.86vw;
    display: flex;
    justify-content: center;
  }
  .body--impressum .datenschutz-impressum {
    margin-bottom: 0vw;
  }
  .datenschutz-impressum__text {
    font-size: 5.7vw;
    line-height: 6.65vw;
  }
  .datenschutz-impressum__text p {
    margin: 5.7vw 0vw 5.7vw 0vw;
  }
  .datenschutz-impressum__text p:first-child {
    margin: 0vw 0vw 0vw 0vw;
  }
  .datenschutz-impressum__text p:last-child {
    margin: 0vw 0vw 0vw 0vw;
  }
}
@media (min-width: 800px) {
  .datenschutz-impressum {
    display: flex;
    justify-content: center;
    margin-bottom: 0.91vw;
  }
  .body--datenschutz .datenschutz-impressum {
    margin-bottom: 13.39vw;
  }
  .datenschutz-impressum__text {
    font-size: 1.37vw;
    line-height: 1.55vw;
    width: calc(((100% - 8.19vw) / 10 * 6 + 4.55vw) * 1 * 1);
  }
  .datenschutz-impressum__text p {
    margin: 1.73vw 0vw 1.73vw 0vw;
  }
  .datenschutz-impressum__text p:first-child {
    margin: 0vw 0vw 0vw 0vw;
  }
  .datenschutz-impressum__text p:last-child {
    margin: 0vw 0vw 0vw 0vw;
  }
}
.duo__introtext {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
}
.duo__duobild {
  overflow: hidden;
  position: relative;
}
.duo__duobild iframe {
  opacity: 1;
  position: absolute;
}
.duo__blurbild {
  display: block;
  filter: blur(80px);
  height: 100%;
  position: absolute;
  transform: scale(1.25);
  width: 100%;
  z-index: -1;
}
.duo__person {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.duo__person-name {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.duo__link {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
  text-decoration: none;
  color: #36deff;
}

@media (max-width: 799px) {
  .duo {
    margin-bottom: 40.86vw;
  }
  .duo__bildcontainer {
    margin-left: -3.33vw;
    margin-right: -3.33vw;
  }
  .duo__duobild {
    height: 71.73vw;
    width: 100%;
  }
  .duo__duobild--desktop {
    display: none;
  }
  .duo__duobild--mobile {
    display: block;
  }
  .duo__container {
    margin-top: 9.5vw;
  }
  .duo__introtext {
    font-size: 7.13vw;
    line-height: 7.6vw;
  }
  .duo__person {
    font-size: 4.75vw;
    line-height: 5.7vw;
    margin: 9.5vw 0vw 9.5vw 0vw;
  }
}
@media (min-width: 800px) {
  .duo__duobild {
    height: calc(100vh - 12.07vw);
  }
  .duo__duobild--mobile {
    display: none;
  }
  .duo__duobild--desktop {
    display: block;
  }
  .duo__duobild iframe {
    left: 50%;
    min-height: 80vh;
    bottom: 0;
    transform: translateX(-50%);
    width: 100vw;
  }
  .duo__container {
    margin-top: 4.46vw;
    transform: translateY(-12.39vw);
  }
  .duo__introtext {
    margin-bottom: 4.46vw;
    font-size: 3.19vw;
    line-height: 3.19vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
    margin-right: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
  .duo__person {
    margin: 6.83vw 0vw 6.83vw 0vw;
    font-size: 1.37vw;
    line-height: 1.55vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
    margin-right: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
}
.header__logo-startseite {
  display: none;
}
.body--startseite .header__logo-startseite {
  display: block;
}
.header__logo-svg {
  width: 100%;
}
.body--startseite .header__logo {
  display: none;
}
.header__menue {
  background-color: black;
}
.header__seitentitel-container {
  text-decoration: none;
}
.header__seitentitel {
  color: #36deff;
  text-decoration: none;
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}
.header__seitentitel--datenschutz-impressum, .header__seitentitel--person {
  color: #36deff;
  text-decoration: none;
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
  text-transform: capitalize;
}
.header__seitentitel-person-container {
  text-decoration: none;
}
.header__link {
  color: white;
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
  text-decoration: none;
}
.header__link:hover {
  color: #36deff;
}
.header__link--datenschutz-impressum, .header__link--person {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
  text-decoration: none;
}

@media (max-width: 799px) {
  .header {
    margin: 0vw 2.38vw 0vw 2.38vw;
    display: flex;
    min-height: calc(var(--vh, 1vh) + 33.25vw);
    justify-content: center;
    position: relative;
  }
  .header__logo {
    left: 0;
    margin-left: calc(((100% - 7.14vw) / 4 * 0 + 6.664vw) * 1 * 1);
    position: fixed;
    top: 5.94vw;
    z-index: 50;
  }
  .header__logo-svg {
    height: 21.38vw;
    width: 21.38vw;
  }
  .header__logo-startseite {
    left: 0;
    position: fixed;
    top: 12.83vw;
    transform: translateX(33.1%);
  }
  .header__logo-startseite .header__logo-svg-startseite {
    height: 60.33vw;
    width: 60.33vw;
  }
  .header__seitentitel-container {
    position: absolute;
    top: 11.88vw;
    left: 50%;
    transform: translateX(-50%);
  }
  .body--impressum .header__seitentitel-container, .body--datenschutz .header__seitentitel-container {
    position: absolute;
    top: 13.3vw;
    left: 52.3%;
    transform: translateX(-50%);
  }
  .header__seitentitel {
    font-size: 8.08vw;
    line-height: 8.55vw;
  }
  .body--impressum .header__seitentitel, .body--datenschutz .header__seitentitel {
    font-size: 5.7vw;
    line-height: 5.7vw;
  }
  .header__seitentitel--datenschutz-impressum, .header__seitentitel--person {
    font-size: 5.7vw;
    line-height: 5.7vw;
  }
  .header__seitentitel-person-container {
    position: absolute;
    top: 13.3vw;
    left: 52.3%;
    transform: translateX(-50%);
  }
  .header__logo-svg {
    display: block;
  }
  .header__logo-svg-startseite {
    width: 100%;
  }
  .header__menue {
    padding: 25.65vw 0vw 8.08vw 12.83vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(100%);
    transition: 0.5s;
    width: 100%;
    z-index: 90;
    overflow-y: auto;
  }
  .body[data-menue-aktiv=true] .header__menue {
    transform: translate(0);
    transition: 0.5s;
  }
  .header__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    padding: 4.75vw 4.75vw 4.75vw 4.75vw;
    padding-right: 9.5vw;
    position: fixed;
    right: 0;
    top: 8.43vw;
    z-index: 9999999;
  }
  .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    background-color: black;
    transition: 0s 0.12s linear;
  }
  .header__hamburger .hamburger-box {
    width: 8.55vw;
    height: 6.41vw;
    display: inline-block;
    position: relative;
  }
  .header__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.595vw;
  }
  .header__hamburger .hamburger-inner, .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    width: 8.55vw;
    height: 1.19vw;
    background-color: black;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .header__hamburger .hamburger-inner::before {
    top: -2.61vw;
  }
  .header__hamburger .hamburger-inner::after {
    bottom: -2.61vw;
  }
  .header__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .header__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .header__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
@media (max-width: 799px) and (max-width: 799px) {
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
@media (max-width: 799px) {
  .body[data-menue-aktiv=true] .header .hamburger-inner,
  .body[data-menue-aktiv=true] .header .hamburger-inner::before,
  .body[data-menue-aktiv=true] .header .hamburger-inner::after {
    background-color: white;
  }
  .header__datenschutz-impressum {
    margin-top: 26.13vw;
    display: flex;
    align-items: flex-end;
  }
  .header__link {
    font-size: 14.25vw;
    line-height: 14.25vw;
  }
  .header__link--datenschutz-impressum, .header__link--person {
    font-size: 8.08vw;
    line-height: 9.26vw;
  }
  .header__link--datenschutz-impressum--1, .header__link--person--1 {
    padding-top: 3.56vw;
  }
  .header__link--datenschutz-impressum {
    margin: 0vw 1.66vw 0vw 1.66vw;
    font-size: 4.75vw;
    line-height: 5.7vw;
    font-weight: 400;
    font-family: "Gilroy Regular", sans-serif;
  }
}
@media (min-width: 800px) {
  .header {
    margin: 0vw 0.91vw 0vw 0.91vw;
    align-items: center;
    display: flex;
    height: 11.16vw;
    justify-content: center;
    position: relative;
  }
  .header__logo {
    margin-left: 9.2vw;
    left: 0;
    position: fixed;
    top: 1.82vw;
    z-index: 120;
  }
  .header__logo-svg {
    display: block;
    height: 7.47vw;
    width: 7.47vw;
  }
  .body--startseite .header__logo-startseite {
    margin-left: 4.24vw;
    left: 0;
    position: fixed;
    top: 4.24vw;
    z-index: 100;
  }
  .body--startseite .header__logo-startseite .header__logo-svg-startseite {
    height: 13.67vw;
    width: 13.67vw;
  }
  .header__seitentitel {
    font-size: 3.19vw;
    line-height: 3.19vw;
  }
  .header__seitentitel--datenschutz-impressum, .header__seitentitel--person {
    font-size: 1.82vw;
    line-height: 2.19vw;
  }
  .header__menue {
    padding-top: 4.01vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    width: 25.06vw;
    z-index: 130;
    transform: translate(100%);
    transition: 0.5s;
  }
  .body[data-menue-aktiv=true] .header__menue {
    transform: translate(0);
    transition: 0.5s;
  }
  .body[data-seite-hover=true] .header__menue {
    transform: translate(1.37vw);
    transition: 0.5s;
  }
  .body--startseite .header__menue {
    background: none;
    transform: translate(0%);
    transition: 0s;
  }
  .header__hamburger {
    display: block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    line-height: 0;
    margin: -0.91vw -0.46vw 0vw 0vw;
    padding: 0.91vw 0.91vw 0.91vw 0.91vw;
    position: fixed;
    right: 10.39vw;
    top: 4.37vw;
    z-index: 120;
  }
  .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger:hover {
    opacity: 1;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before,
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    background-color: black;
    transition: 0s 0.12s linear;
  }
  .header__hamburger .hamburger-box {
    width: 2.73vw;
    height: 2.19vw;
    display: inline-block;
    position: relative;
  }
  .header__hamburger .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -0.18vw;
  }
  .header__hamburger .hamburger-inner, .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    width: 2.73vw;
    height: 0.36vw;
    background-color: black;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .header__hamburger .hamburger-inner::before, .header__hamburger .hamburger-inner::after {
    content: "";
    display: block;
  }
  .header__hamburger .hamburger-inner::before {
    top: -0.915vw;
  }
  .header__hamburger .hamburger-inner::after {
    bottom: -0.915vw;
  }
  .header__hamburger .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .header__hamburger .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .header__hamburger .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
@media (min-width: 800px) and (max-width: 799px) {
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  body[data-menue-aktiv=true] .header__hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
@media (min-width: 800px) {
  .body--startseite .header__hamburger {
    display: none;
  }
  .header__datenschutz-impressum {
    position: absolute;
    bottom: 1.73vw;
    left: calc(((100% - 2.275vw) / 3.5 * 0.82 - 0.1638vw) * 1 * 1 - 0.52vw);
    display: flex;
    align-items: flex-end;
  }
  .header__link {
    font-size: 3.19vw;
    line-height: 3.19vw;
    margin-left: calc(((100% - 2.275vw) / 3.5 * 0.82 - 0.1638vw) * 1 * 1);
  }
  .header__link--datenschutz-impressum, .header__link--person {
    font-size: 1.82vw;
    line-height: 2.05vw;
  }
  .header__link--datenschutz-impressum--1, .header__link--person--1 {
    padding-top: 2.28vw;
  }
  .header__link--datenschutz-impressum {
    margin: 0vw 0.52vw 0vw 0.52vw;
    font-size: 0.91vw;
    line-height: 1.14vw;
    font-weight: 400;
    font-family: "Gilroy Regular", sans-serif;
  }
  .body--startseite .header__link {
    color: black;
  }
  .body--startseite .header__link:hover {
    color: #36deff;
  }
}
.impressum__credits {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.impressum__credits strong {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.impressum__credits a {
  text-decoration: none;
  color: #000;
}

@media (max-width: 799px) {
  .impressum {
    margin-top: 19.95vw;
    margin-bottom: 40.86vw;
  }
  .impressum__credits {
    font-size: 5.7vw;
    line-height: 6.65vw;
  }
  .impressum__credits p {
    margin: 5.7vw 0vw 5.7vw 0vw;
  }
  .impressum__credits p:first-child {
    margin: 0vw 0vw 0vw 0vw;
  }
  .impressum__credits p:last-child {
    margin: 0vw 0vw 0vw 0vw;
  }
}
@media (min-width: 800px) {
  .impressum {
    margin-top: 4.56vw;
    display: flex;
    justify-content: center;
  }
  .impressum__credits {
    margin-bottom: 13.39vw;
    font-size: 1.37vw;
    line-height: 1.55vw;
    width: calc(((100% - 8.19vw) / 10 * 6 + 4.55vw) * 1 * 1);
  }
  .impressum__credits p {
    margin: 1.73vw 0vw 1.73vw 0vw;
  }
  .impressum__credits p:first-child {
    margin: 0vw 0vw 0vw 0vw;
  }
  .impressum__credits p:last-child {
    margin: 0vw 0vw 0vw 0vw;
  }
}
.kontakt__personen {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.kontakt__allgemein {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.kontakt a[href^=tel], .kontakt a[href^=mailto] {
  color: #000;
  text-decoration: none;
}

@media (max-width: 799px) {
  .kontakt {
    margin-bottom: 2.38vw;
    font-size: 5.7vw;
    line-height: 6.65vw;
  }
  .kontakt__allgemein-abschnitt--1 {
    margin-bottom: 9.5vw;
  }
  .kontakt__allgemein-abschnitt--2 {
    margin-bottom: 9.5vw;
  }
  .kontakt__personen {
    margin-bottom: 12.83vw;
  }
  .kontakt__person {
    margin-bottom: 9.5vw;
  }
  .kontakt__karte-container {
    margin-top: 12.83vw;
    margin-left: -3.33vw;
    margin-right: -3.33vw;
  }
  .kontakt__karte {
    height: 127.79vw;
    width: 100%;
  }
}
@media (min-width: 800px) {
  .kontakt {
    display: flex;
    flex-wrap: wrap;
  }
  .kontakt__personen {
    font-size: 1.37vw;
    line-height: 1.55vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 1 + 1.82vw) * 1 * 1);
    width: calc(((100% - 8.19vw) / 10 * 2 + 0.91vw) * 1 * 1);
  }
  .kontakt__person {
    margin: 0vw 0vw 1.59vw 0vw;
  }
  .kontakt__allgemein {
    font-size: 1.37vw;
    line-height: 1.55vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
    width: calc(((100% - 8.19vw) / 10 * 2 + 0.91vw) * 1 * 1);
  }
  .kontakt__allgemein-abschnitt--1 {
    margin: 0vw 0vw 1.59vw 0vw;
  }
  .kontakt__allgemein-abschnitt--2 {
    margin: 1.59vw 0vw 0vw 0vw;
  }
  .kontakt__karte-container {
    margin: 4.46vw 0vw 0.91vw 0vw;
    width: 100%;
  }
  .kontakt__karte {
    height: 37.31vw;
  }
}
.person__introtext, .person__inhalt {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.person__inhalt-bild {
  overflow: hidden;
}
.person__bild-video {
  position: relative;
}
.person__inhalt-bild iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.person__blurbild {
  display: block;
  filter: blur(80px);
  height: 100%;
  position: absolute;
  transform: scale(1.25);
  width: 100%;
  z-index: -1;
}
.person__inhalt--1 .person__inhalt-text {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.person__inhalt--2 .person__inhalt-titel {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
}
.person__inhalt a {
  color: #36deff;
  text-decoration: none;
}

@media (max-width: 799px) {
  .person__introtext {
    margin-bottom: 9.5vw;
    font-size: 5.7vw;
    line-height: 6.65vw;
  }
  .person__spaltenbreite {
    width: 100%;
  }
  .person__spaltenabstand {
    width: 0;
  }
  .person__inhalt {
    width: 100%;
  }
  .person__inhalt--1 {
    padding-bottom: 9.5vw;
  }
  .person__inhalt:nth-last-child(1) {
    padding-bottom: 24.94vw;
  }
  .person__inhalt--1 .person__inhalt-titel {
    font-size: 4.75vw;
    line-height: 5.7vw;
  }
  .person__inhalt--1 .person__inhalt-text {
    font-size: 4.75vw;
    line-height: 5.7vw;
  }
  .person__inhalt--2 .person__inhalt-titel {
    font-size: 7.13vw;
    line-height: 7.13vw;
    margin-bottom: 4.28vw;
  }
  .person__inhalt--2 .person__inhalt-text {
    font-size: 4.75vw;
    line-height: 5.94vw;
  }
  .person__inhalt-bild {
    margin: 9.5vw -3.33vw 4.75vw -3.33vw;
  }
  .person__inhalt-bild--1 {
    margin: 0vw -3.33vw 9.5vw -3.33vw;
  }
}
@media (min-width: 800px) {
  .person {
    margin-bottom: 17.86vw;
    margin-left: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
    margin-right: calc(((100% - 8.19vw) / 10 * 2 + 1.82vw) * 1 * 1);
  }
  .person__introtext {
    margin-bottom: 4.46vw;
    font-size: 1.82vw;
    line-height: 2.19vw;
  }
  .person__inhalte {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .person__spaltenbreite {
    width: calc(((100% - 20.93vw) / 24 * 11 + 9.1vw) * 1 * 1);
  }
  .person__spaltenabstand {
    width: calc(((100% - 20.93vw) / 24 * 2 + 2.73vw) * 1 * 1);
  }
  .person__inhalt {
    margin-bottom: 4.46vw;
    font-size: 0.91vw;
    line-height: 1.09vw;
    display: block;
    width: calc(((100% - 20.93vw) / 24 * 11 + 9.1vw) * 1 * 1);
  }
  .person__inhalt--2 .person__inhalt-titel {
    font-size: 1.37vw;
    line-height: 1.37vw;
    margin-bottom: 0.68vw;
  }
  .person__inhalt-bild {
    margin-bottom: 1.37vw;
  }
}
.projekt__titel, .projekt__auftraggeber {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}
.projekt__text {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
  hyphens: auto;
}
.projekt__text a {
  color: #36deff;
  text-decoration: none;
}
.projekt__details {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
}
.projekt__details a {
  color: #36deff;
  text-decoration: none;
}
.projekt__bild-video {
  position: relative;
  overflow: hidden;
}
.projekt__introbild iframe, .projekt__bild iframe {
  background-color: transparent;
  border: none;
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.projekt__blurbild {
  display: block;
  filter: blur(80px);
  height: 100%;
  position: absolute;
  transform: scale(1.25);
  width: 100%;
  z-index: -1;
}

@media (max-width: 799px) {
  .projekt {
    padding-bottom: 12.83vw;
  }
  .projekt__introbild {
    margin: 0vw -3.33vw 9.5vw -3.33vw;
  }
  .projekt__beschreibung {
    margin: 9.5vw 0vw 9.5vw 0vw;
  }
  .projekt__titel {
    font-size: 8.08vw;
    line-height: 8.08vw;
    padding-bottom: 4.75vw;
  }
  .projekt__text {
    font-size: 5.7vw;
    line-height: 6.18vw;
  }
  .projekt__nebeninformation--desktop {
    display: none;
  }
  .projekt__nebeninformation--mobile {
    margin: 9.5vw 0vw 9.5vw 0vw;
    font-size: 4.75vw;
    line-height: 5.7vw;
  }
  .projekt__bild {
    margin: 2.38vw -3.33vw 2.38vw -3.33vw;
  }
  .projekt__bild--0 {
    display: none;
  }
  .projekt__bilder {
    margin-bottom: 12.83vw;
  }
}
@media (min-width: 800px) {
  .projekt {
    display: flex;
    justify-content: space-between;
  }
  .projekt__spalte--1 {
    width: calc(((100% - 8.19vw) / 10 * 8 + 6.37vw) * 1 * 1);
  }
  .projekt__spalte--2 {
    margin-bottom: 7.02vw;
    display: flex;
    flex-direction: column;
    width: calc(((100% - 8.19vw) / 10 * 2 + 0.91vw) * 1 * 1);
  }
  .projekt__introbild {
    display: none;
  }
  .projekt__bilder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .projekt__bild {
    margin-bottom: 0.91vw;
    width: 100%;
  }
  .projekt__bild--desktop {
    margin-bottom: 0.91vw;
  }
  .projekt__bild--klein {
    width: calc(((100% - 6.37vw) / 8 * 4 + 2.73vw) * 1 * 1);
  }
  .projekt__beschreibung {
    margin-bottom: 4.46vw;
    margin-left: calc(((100% - 6.37vw) / 8 * 1 + 0.91vw) * 1 * 1);
    width: calc(((100% - 8.19vw) / 10 * 6 + 4.55vw) * 1 * 1);
  }
  .projekt__titel {
    font-size: 1.82vw;
    line-height: 1.82vw;
    margin-bottom: 1.59vw;
  }
  .projekt__text {
    font-size: 1.37vw;
    line-height: 1.55vw;
  }
  .projekt__nebeninformation--desktop {
    margin: 0vw 0.91vw 0vw 0.91vw;
    padding-bottom: 4.46vw;
    font-size: 0.91vw;
    line-height: 1.14vw;
    position: sticky;
    top: 0;
    opacity: 0;
  }
  .projekt__nebeninformation--mobile {
    display: none;
  }
  .projekt__auftraggeber {
    font-weight: 800;
  }
}
.projekte__projekt {
  position: relative;
}
.projekte__text {
  color: black;
  text-decoration: none;
}
.projekte__bild-video {
  position: relative;
  overflow: hidden;
}
.projekte__bild iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  outline: none;
}
.projekte__auftraggeber {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
  margin: 0;
}
.projekte__details {
  font-family: "Gilroy Regular", sans-serif;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 799px) {
  .projekte {
    padding-bottom: 12.83vw;
  }
  .projekte__projekt {
    padding: 0vw 0vw 9.5vw 0vw;
    font-size: 5.7vw;
    line-height: 6.65vw;
  }
  .projekte__projekt:last-child {
    padding-bottom: 0vw;
  }
  .projekte__bild {
    margin: 0vw -3.33vw 4.75vw -3.33vw;
  }
  .projekte__details {
    font-size: 5.7vw;
    line-height: 6.65vw;
  }
  .projekte__auftraggeber {
    font-size: 5.7vw;
    line-height: 6.65vw;
    font-family: "Gilroy ExtraBold", sans-serif;
  }
  .projekte__pfeil-container {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 555;
  }
  .projekte__text::after {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .projekte__pfeil {
    display: none;
  }
}
@media (min-width: 800px) {
  .projekte {
    display: flex;
    flex-wrap: wrap;
    margin: -0.46vw 0vw 0.91vw -0.46vw;
  }
  .projekte__projekt {
    padding: 0vw 0.46vw 0vw 0.46vw;
    margin-top: 0.91vw;
    position: relative;
    width: 50%;
  }
  .projekte__projekt:nth-child(1), .projekte__projekt:nth-child(2) {
    margin: 0vw 0vw 0vw 0vw;
  }
  .projekte__projekt:hover .projekte__pfeil-container {
    opacity: 1;
  }
  .projekte__projekt[data-aktiv=true] .projekte__bild-bild,
  .projekte__projekt[data-aktiv=true] .projekte__bild-video {
    filter: blur(50px);
    transform: scale(1.2);
    transition: scale 0.4s, transform 0.4s;
  }
  .projekte__projekt[data-aktiv=true] .projekte__beschreibung {
    opacity: 1;
  }
  .projekte__bild {
    overflow: hidden;
  }
  .projekte__bild-bild {
    transition: filter 0.4s, transform 0.4s;
  }
  .projekte__beschreibung {
    padding: 2.19vw 1.82vw 0vw 6.56vw;
    opacity: 0;
    justify-content: space-between;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 110;
    transition: opacity 0.2s;
  }
  .projekte__auftraggeber {
    font-size: 1.82vw;
    line-height: 2.19vw;
    z-index: 110;
  }
  .projekte__details {
    font-size: 1.82vw;
    line-height: 2.19vw;
    z-index: 110;
  }
  .projekte__text {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .projekte__pfeil-container {
    padding: 1.82vw 1.82vw 0vw 1.82vw;
    align-items: flex-end;
    top: 0;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    position: absolute;
    left: 0;
  }
  .projekte__pfeil-container:hover .projekte__pfeil {
    transform: translate(0.73vw);
    transition: transform 0.3s;
  }
  .projekte__pfeil {
    align-items: center;
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    transition: transform 0.6s;
    height: 2.92vw;
    width: 2.92vw;
  }
  .projekte__pfeil:hover {
    cursor: pointer;
  }
  .projekte__pfeil-svg {
    width: 1.73vw;
  }
}
.startseite__projekt-titel {
  color: #36deff;
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 900;
  text-decoration: none;
}
.startseite__projekt-titel:hover {
  text-decoration: underline;
}
.startseite__video, .startseite__bild {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
.startseite__iframe {
  left: 50%;
  min-height: 100vh;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}
.startseite__blurbild {
  display: block;
  filter: blur(80px);
  height: 100%;
  position: absolute;
  transform: scale(1.25);
  width: 100%;
  z-index: -1;
}

@media (max-width: 799px) {
  .startseite {
    height: calc(var(--vh, 1vh) * 100 - 33.25vw);
  }
  .startseite__projekt-titel {
    font-size: 4.75vw;
    line-height: 5.94vw;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 4.75vw;
    margin-left: calc(((100% - 7.14vw) / 4 * 0 + 2.38vw) * 1 * 1);
    width: calc(((100% - 7.14vw) / 4 * 3 + 4.76vw) * 1 * 1);
  }
}
@media (min-width: 800px) {
  .startseite {
    align-items: flex-end;
    display: flex;
    height: calc(100vh - 11.16vw);
  }
  .startseite::after {
    position: absolute;
    content: " ";
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.4) 100%);
    height: 14.12vw;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .startseite__projekt-titel {
    font-size: 1.46vw;
    line-height: 1.64vw;
    align-items: flex-end;
    display: flex;
    margin-bottom: 1.82vw;
    margin-left: calc(((100% - 11.83vw) / 14 * 0 + 0.91vw) * 1 * 1);
  }
}
.ufe__text {
  font-family: "Gilroy ExtraBold", sans-serif;
  font-weight: 800;
}

@media (max-width: 799px) {
  .ufe {
    margin-bottom: 19.95vw;
  }
  .ufe--desktop {
    display: none;
  }
  .ufe--mobile {
    padding: 4.75vw 4.75vw 4.75vw 4.75vw;
  }
  .ufe--mobile:hover .ufe__pfeil {
    transform: translateY(-1.9vw);
  }
  .ufe__container {
    display: flex;
    justify-content: center;
  }
  .ufe__pfeil {
    height: 7.84vw;
  }
  .ufe__text {
    font-size: 4.75vw;
    line-height: 4.75vw;
    padding-top: 2.38vw;
  }
}
@media (min-width: 800px) {
  .ufe {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .ufe:hover .ufe__pfeil {
    transform: translateY(-0.36vw);
  }
  .ufe--desktop {
    padding: 0.46vw 0.46vw 0.46vw 0.46vw;
    margin: 0vw 2.28vw 1.59vw 0vw;
  }
  .ufe--mobile {
    display: none;
  }
  .ufe__pfeil {
    height: 1.59vw;
    width: 1.18vw;
  }
  .ufe__text {
    font-size: 0.91vw;
    line-height: 0.91vw;
    padding-top: 0.46vw;
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.dev-toolbar {
  background-color: lightgreen;
  display: none;
  font-size: 12px;
  left: 0;
  padding: 5px 10px;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 9999999;
}
body[data-dev=true] .dev-toolbar {
  display: flex;
}
body[data-dev=true] .dev-toolbar::before {
  content: "";
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
}

@media (max-width: 799px) {
  body[data-dev=true] .dev-toolbar::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0.5px, transparent 0.5px) 0 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) 1.19vw 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) -1.19vw 0;
    background-size: calc((100% - 9.52vw) * 0.25 + 2.38vw) 1px;
    margin: 0 1.19vw 0 1.19vw;
    width: calc(100% - 2.38vw + 1.19vw - 2.38vw + 1.19vw);
  }
}
@media (min-width: 800px) {
  body[data-dev=true] .dev-toolbar::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0.5px, transparent 0.5px) 0 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) 0.455vw 0, linear-gradient(90deg, rgba(0, 0, 255, 0.5) 0.5px, transparent 0.5px) -0.455vw 0;
    background-size: calc((100% - 9.1vw) * 0.1 + 0.91vw) 1px;
    margin: 0 0.455vw 0 0.455vw;
    width: calc(100% - 0.91vw + 0.455vw - 0.91vw + 0.455vw);
  }
}
.seite {
  position: relative;
}

@media (max-width: 799px) {
  .seite {
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
}

@media (max-width: 799px) {
  .main {
    padding: 0vw 5.7vw 0vw 5.7vw;
  }
}
@media (min-width: 800px) {
  .main {
    padding: 0vw 0.91vw 0vw 0.91vw;
  }
  body[data-menue-aktiv=true] .main {
    pointer-events: none;
  }
}