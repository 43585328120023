@include mediaquery(smartphone) {

    $layout: smartphone;

    .alli {
        display: none;
    }
}

@include mediaquery(desktop) {

    $layout: desktop;

    .alli {
        @include abstand($layout, margin, top, -20);
        height: 100%;
        position: absolute;
        left: 0;
        margin-left: calc(spalten($layout, 1, 40, 0, 1) - groesse($layout, 20));
        top: 0;
        z-index: 1;
        
        &__link {
            @include abstand($layout, padding, alle, 20, 20, 20, 20);
            align-items: center;
            cursor: pointer;
            display: flex;
            position: sticky;
            text-decoration: none;

            &:hover {

                & .alli__pfeil {
                    transform: translateX(groesse($layout, -16));
                }
            }
        }


        &__pfeil {
            width: groesse($layout, 33 + 2);
            height: groesse($layout, 34 + 2);
        }

        &__text {
            @include schriftart($layout, 20, 20);
            color: black;
            font-family: 'Gilroy ExtraBold', sans-serif;
            font-weight: 800;
            padding-left: groesse($layout, 10);
        }
    }
}