.header {

    &__logo-startseite {
        display: none;

        .body--startseite & {
            display: block;
        }
    }

    &__logo-svg {
        width: 100%;
    }

    .body--startseite & {
        &__logo {
            display: none;
        }
    }

    &__menue {
        background-color: black;
    }

    &__seitentitel-container {
        text-decoration: none;
    }

    &__seitentitel {
        color: map-get($farben, hellblau-text);
        text-decoration: none;
        font-family: "Gilroy ExtraBold", sans-serif;
        font-weight: 800;
        text-transform: capitalize;

        &--datenschutz-impressum, &--person {
            color: map-get($farben, hellblau-text);
            text-decoration: none;
            font-family: 'mrs-eaves-xl-serif', serif;
            font-weight: 900;
            text-transform: capitalize;
        }
    }

    &__seitentitel-person-container {
        text-decoration: none;
    }

    &__link {
        color: white;
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
        text-decoration: none;

        &:hover {
            color: map-get($farben, hellblau-text);
        } 

        &--datenschutz-impressum, &--person {
            font-family: 'mrs-eaves-xl-serif', serif;
            font-weight: 900;
            text-decoration: none;
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .header {
        @include abstand($layout, margin, alle, 0, 10, 0, 10);
        display: flex;
        min-height: calc((var(--vh, 1vh) + groesse($layout, 140)));
        justify-content: center;
        position: relative;

        &__logo {
            left: 0;
            margin-left: spalten($layout, 0, 4, 0, 3.8);
            position: fixed;
            top: groesse($layout, 25);
            z-index: 50;
        }

        &__logo-svg {
            height: groesse($layout, 90);
            width: groesse($layout, 90);
        }

        &__logo-startseite {
            left: 0;
            position: fixed;
            top: groesse($layout, 54);
            transform: translateX(33.1%);

            .header__logo-svg-startseite {
                height: groesse($layout, 254);
                width: groesse($layout, 254);
            }
        }

        &__seitentitel-container {
            position: absolute;
            top: groesse($layout, 50);
            left: 50%;
            transform: translateX(-50%);

            .body--impressum &,
            .body--datenschutz & {
                position: absolute;
                top: groesse($layout, 56);
                left: 52.3%;
                transform: translateX(-50%);
            }
        }

        &__seitentitel {
            @include schriftart($layout, 34, 36);

            .body--impressum &,
            .body--datenschutz & {
                @include schriftart($layout, 24, 24);
            }

            &--datenschutz-impressum, &--person {
                @include schriftart($layout, 24, 24);
            }
        }

        &__seitentitel-person-container {
            position: absolute;
            top: groesse($layout, 56);
            left: 52.3%;
            transform: translateX(-50%);
        }

        &__logo-svg {
            display: block;
        }

        &__logo-svg-startseite {
            width: 100%;
        }

        &__menue {
            @include abstand($layout, padding, alle, (54 * 2), 0, 34, 54);
            display: flex;
            flex-direction: column;
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            transform: translate(100%);
            transition: 0.5s;
            width: 100%;
            z-index: 90;
            overflow-y: auto;

            .body[data-menue-aktiv="true"] & {
                transform: translate(0);
                transition: 0.5s;
            }
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 36);
            $hamburger-hoehe: groesse($layout, 27);
            $hamburger-strichstaerke: groesse($layout, 5);
            $hamburger-farbe: black;
            $hamburger-farbe-aktiv: black;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, padding, alle, 20, 20, 20, 20);
            padding-right: groesse($layout, 40);
            position: fixed;
            right: 0;
            top: groesse($layout, 35.5);
            z-index: 9999999;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {

            .body[data-menue-aktiv="true"] & {
                background-color: white;
            }
        }

        &__datenschutz-impressum {
            @include abstand($layout, margin, top, 110);
            display: flex;
            align-items: flex-end;
        }

        &__link {
            @include schriftart($layout, 60, 60);

            &--datenschutz-impressum, &--person {
                @include schriftart($layout, 34, 39);

                &--1 {
                    @include abstand($layout, padding, top, 15);
                }
            }

            &--datenschutz-impressum {
                @include abstand($layout, margin, alle, 0, 7, 0, 7);
                @include schriftart($layout, 20, 24, 400);
                font-family: "Gilroy Regular", sans-serif;
            }
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .header {
        @include abstand($layout, margin, alle, 0, 20, 0, 20);
        align-items: center;
        display: flex;
        height: groesse($layout, 245);
        justify-content: center;
        position: relative;

        &__logo {
            @include abstand($layout, margin, left, (20 + 182));
            left: 0;
            position: fixed;
            top: groesse($layout, 40);
            z-index: 120;
        }

        &__logo-svg {
            display: block;
            height: groesse($layout, 164);
            width: groesse($layout, 164);
        }

        &__logo-startseite {

            .body--startseite & {
                @include abstand($layout, margin, left, 93);
                left: 0;
                position: fixed;
                top: groesse($layout, 93);
                z-index: 100;

                .header__logo-svg-startseite {
                    height: groesse($layout, 300);
                    width: groesse($layout, 300);
                }
            }
        }

        &__seitentitel {
            @include schriftart($layout, 70, 70);

            &--datenschutz-impressum, &--person {
                @include schriftart($layout, 40, 48);
            }
        }

        &__menue {
            @include abstand($layout, padding, top, 88);
            display: flex;
            flex-direction: column;
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            width: groesse($layout, 550);
            z-index: 130;
            transform: translate(100%);
            transition: 0.5s;

            .body[data-menue-aktiv="true"] & {
                transform: translate(0);
                transition: 0.5s;
            }

            .body[data-seite-hover="true"] & {
                transform: translate(groesse($layout, 30));
                transition: 0.5s
            }

            .body--startseite & {
                background: none;
                transform: translate(0%);
                transition: 0s;
            }
        }

        &__hamburger {
            $hamburger-breite: groesse($layout, 60);
            $hamburger-hoehe: groesse($layout, 48);
            $hamburger-strichstaerke: groesse($layout, 8);
            $hamburger-farbe: black;
            $hamburger-farbe-aktiv: black;
            @include hamburger($hamburger-breite, $hamburger-hoehe, $hamburger-strichstaerke, $hamburger-farbe, $hamburger-farbe-aktiv);
            @include abstand($layout, margin, alle, -20, -10, 0, 0);
            @include abstand($layout, padding, alle, 20, 20, 20, 20);
            position: fixed;
            right: groesse($layout, 228);
            top: groesse($layout, 40 + 56);
            z-index: 120;

            .body--startseite & {
                display: none;
            }
        }

        &__datenschutz-impressum {
            position: absolute;
            bottom: groesse($layout, 38);
            left: calc(spalten($layout, 0.82, 3.5, 0, 0) - groesse($layout, 11.5));
            display: flex;
            align-items: flex-end;
        }

        &__link {
            @include schriftart($layout, 70, 70);
            margin-left: spalten($layout, 0.82, 3.5, 0, 0);

            &--datenschutz-impressum, &--person {
                @include schriftart($layout, 40, 45);

                &--1 {
                    @include abstand($layout, padding, top, 50);
                }
            }

            &--datenschutz-impressum {
                @include abstand($layout, margin, alle, 0, 11.5, 0, 11.5);
                @include schriftart($layout, 20, 25, 400);
                font-family: "Gilroy Regular", sans-serif;
            }

            .body--startseite & {
                color: black;

                &:hover {
                    color: map-get($farben, hellblau-text);
                }
            }
        }
    }
}