.angebot {

    &__woerter,
    &__titel {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }
    
    &__introtext,
    &__beschreibung {
        font-family: 'mrs-eaves-xl-serif', serif;
        font-weight: 900;
    }

    &__intro {
        display: flex;
        flex-direction: column;
    }
    
    &__introtext {
        flex: 1;
    }

    &__wort {
        margin: 0;
        transform: translateY(70vw);
        transition: transform 0.5s;
    }
    &__angebote {
        overflow: hidden;
    }

    &__angebot {
        background-color: map-get($farben, hellblau-flaeche);
        display: flex;
        
        &.aktiv {
            .angebot__wort {
                transform: translateY(0);
            }
    
            .angebot__woerter .angebot__wort {
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.4s;
                }
            }
        }
    }

    &__so-text {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }

    &__abschlusstext {
        font-family: "Gilroy Regular", sans-serif;
        font-weight: 400;

        strong {
            font-family: 'Gilroy ExtraBold', sans-serif;
        }

        a {
            text-decoration: none;
            color: map-get($farben, hellblau-text);

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .angebot {

        &__intro {
            min-height: calc(calc(var(--vh, 1vh) * 100) - groesse($layout, 140));
        }

        &__introtext {
            @include schriftart($layout, 30, 32);
        }

        &__angebote {
            @include abstand($layout, margin, alle, 0, -14, 0, -14);
        }

        &__angebot {
            @include abstand($layout, padding, alle, 65, 14, 110, 14);
            flex-direction: column;
            margin-bottom: groesse($layout, 13);
        }

        &__woerter {
            @include schriftart($layout, 50, 50);
            margin-top: groesse($layout, 55);
            order: 2;
        }

        &__text {
            order: 1;
        }

        &__titel {
            @include schriftart($layout, 34, 34);
            margin-bottom: groesse($layout, 20);
        }

        &__beschreibung {
            @include schriftart($layout, 24, 26);
        }

        &__so {
            margin-bottom: groesse($layout, 40);
        }

        &__so-pfeil-svg {
            height: groesse($layout, 30 + 3);
            width: groesse($layout, 38 + 2);
        }

        &__so-text {
            @include schriftart($layout, 20, 20);
            padding-bottom: groesse($layout, 10);
        }

        &__abschlusstext {
            @include abstand($layout, padding, alle, 54, 0, 54, 0);
            @include schriftart($layout, 20, 24);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .angebot {

        &__intro {
            // minus header
            min-height: calc(100vh - groesse($layout, 245));
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center
        }

        &__introtext {
            @include schriftart($layout, 70, 70);
            margin-left: spalten($layout, 2, 10, 0, 1);
            margin-right: spalten($layout, 2, 10, 0, 1);
        }

        &__angebot {
            margin-bottom: groesse($layout, 20);
            padding: groesse($layout, 130) spalten($layout, 1, 10, 0, 1);
        }

        .angebot__text {
            margin-left: spalten($layout, 0, 8, 0, 2);
            width: spalten($layout, 3, 8);
        }

        &__woerter {
            @include schriftart($layout, 140, 130);
            width: spalten($layout, 5, 8, 0, 0);
        }

        &__titel {
            @include schriftart($layout, 40, 40);
            margin-bottom: groesse($layout, 20);
        }

        &__beschreibung {
            @include schriftart($layout, 30, 34);
        }

        &__so {
            margin-bottom: groesse($layout, 98);

            &:hover {
                .angebot__so-pfeil {
                    transform: translateY(groesse($layout, 16));
                }
            }
        }

        
        &__so-pfeil-svg {
            height: groesse($layout, 33 + 2);
            width: groesse($layout, 24 + 2);
        }


        &__so-text {
            @include schriftart($layout, 20, 20);
            padding-bottom: groesse($layout, 10);
        }

        &__abschlusstext {
            @include abstand($layout, padding, alle, 180, 0, 180, 0);
            @include schriftart($layout, 30, 34);
            width: spalten($layout, 6, 10);
            margin: 0 auto 0 auto;
            min-height: 33vh;
        }
    }
}