.kontakt {

    &__personen {
        font-family: "Gilroy Regular", sans-serif;
        font-weight: 400;
    }
    
    &__allgemein {
        font-family: "Gilroy ExtraBold", sans-serif;
        font-weight: 800;
    }

    a[href^=tel], a[href^=mailto] {
        color: #000;
        text-decoration: none;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .kontakt {
        @include abstand($layout, margin, bottom, 10);
        @include schriftart($layout, 24, 28);

        &__allgemein {

            &-abschnitt {

                &--1 {
                    @include abstand($layout, margin, bottom, 40);
                }

                &--2 {
                    @include abstand($layout, margin, bottom, 40);
                }
            }
        }

        &__personen {
            @include abstand($layout, margin, bottom, 54);
        }

        &__person {
            @include abstand($layout, margin, bottom, 40);
        }

        &__karte-container {
            @include abstand($layout, margin, top, 54);
            // 24 main - 10
            margin-left: groesse($layout, -14);
            margin-right: groesse($layout, -14);
        }
        
        &__karte {
            height: groesse($layout, 538);
            width: 100%;
        }
    }
}




@include mediaquery(desktop) {

    $layout: desktop;

    .kontakt {
        display: flex;
        flex-wrap: wrap;

        &__personen {
            @include schriftart($layout, 30, 34);
            margin-left: spalten($layout, 1, 10, 0, 2);
            width: spalten($layout, 2, 10, 0, 0);
        }
        
        &__person {
            @include abstand($layout, margin, alle, 0, 0, 35, 0);
        }
        
        &__allgemein {
            @include schriftart($layout, 30, 34);
            margin-left: spalten($layout, 2, 10, 0, 1);
            width: spalten($layout, 2, 10, 0, 0);

            &-abschnitt {

                &--1 {
                    @include abstand($layout, margin, alle, 0, 0, 35, 0);
                }

                &--2 {
                    @include abstand($layout, margin, alle, 35, 0, 0, 0);
                }
            }
        }

        &__karte-container {
            @include abstand($layout, margin, alle, 98, 0, 20, 0);
            width: 100%;
        }

        &__karte {
            height: groesse($layout, 819);
        }
    }
}