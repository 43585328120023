.projekte {

    &__projekt {
        position: relative;
    }

    &__text {
        color: black;
        text-decoration: none;
    }

    &__bild-video {
        position: relative;
        overflow: hidden;
    }
    
    &__bild iframe {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border: none;
        outline: none;
    }

    &__auftraggeber {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
        margin: 0;
    }

    &__details {
        font-family: "Gilroy Regular", sans-serif;
        font-weight: 400;
        margin: 0;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekte {
        @include abstand($layout, padding, bottom, 54);
        
        &__projekt {
            @include abstand($layout, padding, alle, 0, 0, 40, 0);
            @include schriftart($layout, 24, 28);
            
            &:last-child {
                @include abstand($layout, padding, bottom, 0);
            }
        }

        &__bild {
            @include abstand($layout, margin, alle, 0, -14, 20, -14);
        }

        &__details {
            @include schriftart($layout, 24, 28);
        }
        
        &__auftraggeber {
            @include schriftart($layout, 24, 28);
            font-family: 'Gilroy ExtraBold', sans-serif;
        }

        &__pfeil-container {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 555;
        }

        &__text {
            &::after {
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        &__pfeil {
            display: none;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekte {
        display: flex;
        flex-wrap: wrap;
        @include abstand($layout, margin, alle, -10, 0, 20, -10);

        &__projekt {
            @include abstand($layout, padding, alle, 0, 10, 0, 10);
            @include abstand($layout, margin, top, 20);
            position: relative;
            width: 50%;

            &:nth-child(1),
            &:nth-child(2) {
                @include abstand($layout, margin, alle, 0, 0, 0, 0);
            }

            &:hover {
                .projekte__pfeil-container {
                    opacity: 1;
                }
            }

            &[data-aktiv="true"] {
                .projekte__bild-bild,
                .projekte__bild-video {
                    filter: blur(50px);
                    transform: scale(1.2);
                    transition: scale 0.4s, transform 0.4s;
                }
                .projekte__beschreibung {
                    opacity: 1;
                }
            }
        }

        &__bild {
            overflow: hidden;
            
            &-bild {
                transition: filter 0.4s, transform 0.4s;
            }
        }

        &__beschreibung {
            // 48= 40 + 8, 64 groesse Pfeil
            @include abstand($layout, padding, alle, 48, 40, 0, (40 + 40 + 64));
            opacity: 0;
            justify-content: space-between;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 110;
            transition: opacity 0.2s;
        }
        
        &__auftraggeber {
            @include schriftart($layout, 40, 48);
            z-index: 110;
        }
        
        &__details {
            @include schriftart($layout, 40, 48);
            z-index: 110;
        }

        &__text {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &__pfeil-container {
            @include abstand($layout, padding, alle, 40, 40, 0, 40);
            align-items: flex-end;
            top: 0;
            display: flex;
            justify-content: flex-end;
            opacity: 0;
            position: absolute;
            left: 0;

            &:hover {
                .projekte__pfeil {
                    transform: translate(groesse($layout, 16));
                    transition: transform 0.3s;
                }
            }
        }

        &__pfeil {
            align-items: center;
            background-color: white;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            transition: transform 0.6s;
            height: groesse($layout, 64);
            width: groesse($layout, 64);

            &:hover {
                cursor: pointer;
            }
        }
        
        &__pfeil-svg {
            // pointer-events: none;
            width: groesse($layout, 38);
        }
    }
}