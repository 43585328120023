.projekt {

    &__titel,
    &__auftraggeber {
        font-family: "Gilroy ExtraBold", sans-serif;
        font-weight: 800;
    }

    &__text {
        font-family: 'mrs-eaves-xl-serif', serif;
        font-weight: 900;
        hyphens: auto;
    }

    &__text a {
        color: map-get($farben, hellblau-text);
        text-decoration: none;
    }

    &__details {
        font-family: "Gilroy Regular", sans-serif;
        font-weight: 400;

        & a {
            color: map-get($farben, hellblau-text);
            text-decoration: none;
        }
    }

    &__bild-video {
        position: relative;
        overflow: hidden;
    }

    &__introbild iframe,
    &__bild iframe {
        background-color: transparent;
        border: none;
        height: 100%;
        left: 0;
        outline: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__blurbild {
        display: block;
        filter: blur(80px);
        height: 100%;
        position: absolute;
        transform: scale(1.25);
        width: 100%;
        z-index: -1;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .projekt {
        padding-bottom: groesse($layout, 54);
        
        &__introbild {
            @include abstand($layout, margin, alle, 0, -14, 40, -14);
        }
        
        &__beschreibung {
            @include abstand($layout, margin, alle, 40, 0, 40, 0);
        }
        
        &__titel {
            @include schriftart($layout, 34, 34);
            @include abstand($layout, padding, bottom, 20);
        }
        
        &__text {
            @include schriftart($layout, 24, 26);
        }
        
        &__nebeninformation {
            
            &--desktop {
                display: none;
            }
            
            &--mobile {
                @include abstand($layout, margin, alle, 40, 0, 40, 0);
                @include schriftart($layout, 20, 24);
            }
        }
        
        &__bild {
            @include abstand($layout, margin, alle, 10, -14, 10, -14);

            &--0 {
                display: none;
            }
        }

        &__bilder {
            @include abstand($layout, margin, bottom, 54);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .projekt {
        display: flex;
        justify-content: space-between;
        
        
        &__spalte {
            
            &--1 {
                width: spalten($layout, 8, 10, 0, 0);
            }
            
            &--2 {
                @include abstand($layout, margin, bottom, 154);
                display: flex;
                flex-direction: column;
                width: spalten($layout, 2, 10, 0, 0);
            }
        }
        
        &__introbild {
            display: none;
        }

        &__bilder {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        
        &__bild {
            @include abstand($layout, margin, bottom, 20);
            width: 100%;
            
            &--desktop {
                @include abstand($layout, margin, bottom, 20);
            }

            &--klein {
                width: spalten($layout, 4, 8, 0, 0);
            }
        }

        &__beschreibung {
            @include abstand($layout, margin, bottom, 98);
            margin-left: spalten($layout, 1, 8, 0, 1);
            width: spalten($layout, 6, 10, 0, 0);
        }

        &__titel {
            @include schriftart($layout, 40, 40);
            @include abstand($layout, margin, bottom, 35);
        }

        &__text {
            @include schriftart($layout, 30, 34);
        }

        &__nebeninformation {

            &--desktop {
                @include abstand($layout, margin, alle, 0, 20, 0, 20);
                @include abstand($layout, padding, bottom, 98);
                @include schriftart($layout, 20, 25);
                position: sticky;
                top: 0;
                opacity: 0;
            }

            &--mobile {
                display: none;
            }
        }

        &__auftraggeber {
            font-weight: 800;
        }
    }
}